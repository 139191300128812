<template>
  <v-card>
    <v-card-title>{{ $t("statistics.title") }}</v-card-title>
    <v-tabs v-model="tab" :height="60" background-color="grey lighten-5">
      <v-tab> {{ $t("statistics.users") }} </v-tab>
      <v-tab> {{ $t("statistics.logins") }} </v-tab>
      <v-tab> {{ $t("statistics.issues") }} </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <UsersTab/>
      </v-tab-item>
      <v-tab-item>
        <LogsTab/>
      </v-tab-item>
      <v-tab-item>
        <IssuesTab/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import UsersTab from './tabs/UsersTab'
import LogsTab from './tabs/LogsTab'
import IssuesTab from './tabs/IssuesTab'

export default {
  name: 'Statistics',
  components: {
    UsersTab,
    LogsTab,
    IssuesTab
  },
  data () {
    return {
      tab: null
    }
  }
}
</script>

<style scoped>

</style>
