<template>
  <v-container
    fluid
    class="users-tab px-4"
  >
    <v-row
      v-if="!loading"
      class="my-2"
      no-gutters
    >
      <v-col cols="12" md="6" class="mb-4 mb-md-0">
        <v-card class="mr-md-2 pa-4 fill-height">
          <v-row
            no-gutters
            class="fill-height"
          >
            <v-col cols="12" md="6">
              <v-card-title>
                {{ $t('statistics.activeUsers') }}
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <p>{{ $t('statistics.activeShipper') }}: {{ data.activeShipperCount }}</p>
                    <p>{{ $t('statistics.activeFerryman') }}: {{ data.activeFerrymanCount }}</p>
                    <p>{{ $t('statistics.activeBoth') }}: {{ data.activeBothCount }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col
              cols="12" md="6"
              style="width: 50%; max-width: 280px;"
              class="d-flex align-center mx-auto mx-md-0"
            >
              <Chart
                id="active-user-tab-chart"
                :chart-data="activeUsersChartData"
                :chart-options="chartOptions"
                :chart-type="chartType"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="mb-4 mb-md-0">
        <v-card class="mr-md-2 pa-4 fill-height">
          <v-row
            no-gutters
            class="fill-height"
          >
            <v-col cols="12" md="6">
              <v-card-title>
                {{ $t('statistics.inactiveUsers') }}
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <p>{{ $t('statistics.unactiveShipper') }}: {{ data.unactiveShipperCount }}</p>
                    <p>{{ $t('statistics.unactiveFerryman') }}: {{ data.unactiveFerrymanCount }}</p>
                    <p>{{ $t('statistics.unactiveBoth') }}: {{ data.unactiveBothCount }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col
              cols="12" md="6"
              style="width: 50%; max-width: 280px;"
              class="d-flex align-center mx-auto mx-md-0"
            >
              <Chart
                id="inactive-user-tab-chart"
                :chart-data="inactiveUsersChartData"
                :chart-options="chartOptions"
                :chart-type="chartType"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'
import Chart from '@/components/Chart'

export default {
  name: 'Users',
  components: {
    Chart
  },
  data () {
    return {
      loading: true,
      data: null,
      chartType: 'doughnut',
      chartOptions: {
        legend: {
          display: false
        }
      }
    }
  },
  computed: {
    activeUsersChartData () {
      return {
        labels: [
          this.$t('statistics.activeShipper'),
          this.$t('statistics.activeFerryman'),
          this.$t('statistics.activeBoth')
        ],
        datasets: [{
          data: [this.data.activeShipperCount, this.data.activeFerrymanCount, this.data.activeBothCount],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
      }
    },
    inactiveUsersChartData () {
      return {
        labels: [
          this.$t('statistics.unactiveShipper'),
          this.$t('statistics.unactiveFerryman'),
          this.$t('statistics.unactiveBoth')
        ],
        datasets: [{
          data: [this.data.unactiveShipperCount, this.data.unactiveFerrymanCount, this.data.unactiveBothCount],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data: categoriesIssue } = await Service.get('Statistic/GetUsersCount')
        this.data = categoriesIssue
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
